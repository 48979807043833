@import 'colors.scss';

#current-run-game-tab {
    width: 621px;
    height: 145px;
    position: absolute;
    z-index: 100;
    top: 490px;
    background-position: -83px;
    .info-bg {
        width: 621px;
        height: 145px;
        background-image: url(../resources/gametab/gametab-campi.png);
        position: absolute;
        background-position: 0 59px;
        background-repeat: no-repeat;
    }
    .title {
        color: $title-color;
        width: 100%;
        text-align: center;
        height: 60px;
        opacity: 0;
        text-transform: uppercase;
        font-size: 42px;
        position: absolute;
        top: 5px;
        letter-spacing: 0.4px;
        overflow: hidden;
    }
    .category {
        font-family: edit-undo;
        position: absolute;
        color: $subtitle;
        font-size: 20px;
        top: 72px;
        height: 19px;
        width: 456px;
        text-align: center;
        opacity: 0;
        overflow: hidden;
    }
    .elapsed {
        font-family: edit-undo;
        position: absolute;
        color: $subtitle;
        font-size: 20px;
        top: 72px;
        left: 467px;
        height: 19px;
        width: 154px;
        text-align: center;
        opacity: 0;
    }
    .platform {
        font-family: edit-undo;
        position: absolute;
        color: $subtitle;
        font-size: 20px;
        top: 114px;
        height: 19px;
        width: 490px;
        text-align: center;
        opacity: 0;
    }
    .year {
        font-family: edit-undo;
        position: absolute;
        color: $subtitle;
        font-size: 20px;
        top: 114px;
        left: 501px;
        height: 19px;
        width: 120px;
        text-align: center;
        opacity: 0;
    }
}